<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-process type="search" name="processCd" label="공정" v-model="searchParam.processCd" />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="과거 위험요인 시나리오 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :merge="grid.merge"
      :selection="popupParam.type"
      rowKey="ramChecklistAssessScenarioId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'checklist-scenario-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          ramRiskAssessmentPlanId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'ramRiskAssessmentPlanId' },
          { index: 1, colName: 'processCd' },
        ],
        columns: [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '평가대상',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            required: true,
          },
          {
            name: 'before',
            field: 'before',
            label: '위험성추정',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                label: '가능성',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                label: '중대성',
                align: 'center',
                style: 'width:50px',
                sortable: false
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                label: '위험성',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          {
            name: 'decisionName',
            field: 'decisionName',
            label: '결정',
            align: 'center',
            style: 'width:80px',
            setHeader: true,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            label: '위험성감소대책',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      searchParam: {
        processCd: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.checklist.scenario.list.url

      // code setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request((_result) => {
        this.grid.data = this.$_.reject(_result.data, { ramRiskAssessmentPlanId: 
          this.popupParam.ramRiskAssessmentPlanId}) 
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '위험성평가를 지정하세요.', // 위험성평가를 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
